<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Employee</label>
                          <v-select
                            v-model="employee"
                            label="fullname"
                            placeholder="None"
                            :options="employeeOptions"
                          >
                            <template
                              #option="{ fullname, profile_image, username, surname }"
                            >
                              <b-avatar
                                v-if="fullname != 'All'"
                                :src="getprofileImage(profile_image)"
                              />
                              <span class="font-weight-bolder"> {{ fullname }} </span
                              ><span v-if="fullname != 'All'">({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <!-- <b-col md="6">
                          <b-form-group>
                            <label>Branch</label>
                            <v-select v-model="branch" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None" :options="['Channel Partner','Franchise','Admin']" @input="getEmployee()"
                            />
                          </b-form-group>
                        </b-col> -->
                      <!-- <b-col md="6" >
                          <b-form-group>
                            <label>Select {{ branch }}</label>
                            <v-select v-model="employee" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None" :options="employeeoption" @input="getUsers()"
                            />
                          </b-form-group>
                        </b-col> -->
                      <b-col md="4">
                        <b-form-group>
                          <label>Month</label>
                          <v-select
                            v-model="month"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            placeholder="None"
                            :options="[
                              '1',
                              '2',
                              '3',
                              '4',
                              '5',
                              '6',
                              '7',
                              '8',
                              '9',
                              '10',
                              '11',
                              '12',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Year</label>
                          <v-select
                            v-model="year"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="year"
                            placeholder="None"
                            :options="[
                              '2000',
                              '2001',
                              '2002',
                              '2003',
                              '2004',
                              '2005',
                              '2006',
                              '2007',
                              '2008',
                              '2009',
                              '2010',
                              '2011',
                              '2012',
                              '2013',
                              '2014',
                              '2015',
                              '2016',
                              '2017',
                              '2018',
                              '2019',
                              '2020',
                              '2021',
                              '2022',
                              '2023',
                              '2024',
                              '2025',
                              '2026',
                              '2027',
                              '2028',
                              '2029',
                              '2030',
                              '2031',
                              '2032',
                              '2033',
                              '2034',
                              '2035',
                              '2036',
                              '2037',
                              '2038',
                              '2039',
                              '2040',
                              '2041',
                              '2042',
                              '2043',
                              '2044',
                              '2045',
                              '2046',
                              '2047',
                              '2048',
                              '2049',
                              '2050',
                              '2051',
                              '2052',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="salaryData" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import { BFormGroup, BForm, BRow, BCol, BButton, BAvatar } from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
    BAvatar,
  },
  data() {
    return {
      branch: "",
      employee: "",
      employeeOptions: [],
      startdate: "",
      enddate: "",
      result: "",
      salaryData: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      fields: [
        { field: "id", label: "S.R.No" },
        {
          field: "employee_id",
          label: "Employee Name",
          url: "team",
          type: "dropdown",
          responseValue: "name",
        },
        { field: "machinecode", label: "Machine Code" },
        { field: "perhrsalary", label: "Per Hr Salary" },
        { field: "salary", label: "Salary" },
        { field: "machinehours", label: "Machine Hours" },
        { field: "employeehours", label: "Employee Hours" },
        { field: "gross", label: "Gross Salary" },
        { field: "othr", label: "OT Hr" },
        { field: "otsalary", label: "OT Salary" },
        { field: "holidayhr", label: "Holiday Hr" },
        { field: "holidaysalary", label: "Holiday Salary" },
        { field: "leaves", label: "Leaves" },
        { field: "netsalary", label: "Net Salary" },
        { field: "printsalaryslip", label: "Print Salary Slip" },
      ],
      month: "",
      year: "",
    };
  },
  mounted() {
    this.getEmployee();
  },
  methods: {
    async searchData() {
      const data = {
        branch: this.branch,
        employee: this.employee.id,
        month: this.month,
        year: this.year,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/salaryreport`,
      })
        .then((response) => {
          this.salaryData = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getEmployee(id) {
      this.employeeOptions = [];
      // let url = `getPositionWiseEmployee/${id}`
      // if(id == 'all'){
      //   url='getEmployee'
      // }
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      }).then((response) => {
        this.employeeOptions = response.data.data;
        this.employeeOptions.unshift({ fullname: "All", id: "all" });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
